import React, { useMemo, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import InfiniteScroll from 'react-infinite-scroll-component'
import ClipLoader from 'react-spinners/ClipLoader'

import { useSearch } from 'hooks'
import SEO from 'components/SEO'
import VideoCard from 'components/VideoCard'
import Banner from 'components/Banner'

import styles from './VideoSearch.module.scss'

export const query = graphql`
  query VideoSearchPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  
  }
`

const calcPerPage = (windowWidth) => {
  if (!windowWidth) return 25 // case SSR window does not exist
  if (windowWidth <= 880) return 6
  if (windowWidth <= 1170 ) return 9
  if (windowWidth <= 1470 ) return 12
  return 15
}
/*
const options = {
  fields: {
    title: { boost: 3 },
    categories: { boost: 2 },
    modelAka: { boost: 1 }
  },
  expand: true 
}
*/

const indexName = 'video'
const indexFilePath = '/csr-search-index/video-index.json'

const indexOptions = {
  idField: 'id',
  fields: ['title', 'categories', 'modelAka'], // fields to index for full-text search
}

const queryOptions = {
  fields: ['title', 'categories', 'modelAka'], // fields to search into
  boost: { title: 3, categories: 2, modelAka: 1 },
  prefix: true,
  fuzzy: 0.2,
}

const VideoSearchPage = (props) => {
  const { site: { siteMetadata }, banner } = props.data
  const { query } = queryString.parse(props.location.search) || {}
  const windowWidth = typeof window !== 'undefined' && window.innerWidth
  const perPage = useMemo(() => calcPerPage(windowWidth), [windowWidth])
  const [results, isLoading] = useSearch(indexName, indexFilePath, indexOptions, query, queryOptions)
  const [showingResults, setShowingResults] = useState([])

  const getMoreResults = () => {
    const nextPageResults = results.slice(showingResults.length, showingResults.length + perPage)
    const newShowingResults = showingResults.concat(nextPageResults)
    setShowingResults(newShowingResults)
  }

  useEffect(() => setShowingResults(results.slice(0, perPage)), [results])
  
  const breadcrumb = useMemo(() => {
    
    const schema = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": `${siteMetadata.siteUrl}/videos/`,
            "name": "Videos"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": `${siteMetadata.siteUrl}/videos/search/`,
            "name": "Search"
          }
        },
      ]
    }

    if (query) {
      schema.itemListElement.push({
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${siteMetadata.siteUrl}/videos/search/?query=${query}`,
          "name": `${query}`
        }
      })
    }

    return schema;

  }, [siteMetadata, query])

  const Results = useMemo(() => (
    <>
    <InfiniteScroll
        dataLength={showingResults.length}
        next={getMoreResults}
        hasMore={showingResults.length < results.length}
        loader={<h4>Loading...</h4>}
      >
      <div className={`${styles.videosBlock} video-cards-container`}>
        <VideoList videos={showingResults} />
      </div>
    </InfiniteScroll>
    </>
  ), [showingResults.length, showingResults[0], showingResults[showingResults.length-1]])

  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Enjoy the best ${query} porn videos with the hottest cam girls | SloppyDeep.com`}
      description={`Watch ${query} cam recordings for free on SloppyDeep, the best cam porn tube. Find your favorite show and have fun with a wide selection of hot camgirls ready to masturbate with you in a cam to cam session`}
      keywords={`sloppydeep, ${query} cam recording, ${query} sex cam, live cam chat, ${query} live chat`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={query ? `/videos/search/?query=${query}` : '/videos/search/'}
      robots='index, follow'
    />
    <Helmet>
      { /* prefetch for thumbnails */}
      <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
    </Helmet>
    <main className={`${styles.videoSearchScreen} screen-container`}>
      <h1 className={styles.titleBlock}>Results for: <span className={styles.query}>{query}</span></h1>
      { isLoading &&
        <div className={styles.loader}>
          <ClipLoader
            size={'3rem'}
            color={'#FF8A8A'}
          />
        </div>
      }
      {Results}
    </main>
    </>
  )

}

const VideoList = ({ videos }) => {

  const firstBannerIndex = 4
  const bannerFrequency = 10

  let componentList = []

  for (let i = 0; i < videos.length; i++) {
    const shouldPushBanner = (firstBannerIndex + i) % bannerFrequency === 0

    if (shouldPushBanner) {
      componentList.push(i < 10
        ? <Banner.Livejasmin key={`banner-${i}`} />
        : <Banner.Stripcash key={`banner-${i}`} />)
    }

    componentList.push(<VideoCard key={videos[i].slug} data={videos[i]} />)
  }

  return componentList
}

export default VideoSearchPage